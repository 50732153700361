import { Card } from '@people/ui/cards'

export const LocationsLoader = () => {
  return (
    <Card>
      <div className="border-b border-slate-200 px-[18px] py-3.5">
        <div className="w-[50%] animate-pulse rounded bg-slate-200 py-2.5" />
      </div>
      <div className="border-slate-204 flex flex-col gap-2.5 border-b px-2.5 py-3.5">
        {[...Array(3)].map((_, i) => (
          <div
            key={i}
            className="w-full animate-pulse rounded bg-slate-200 py-3.5"
          />
        ))}
      </div>
      <div className="px-[18px] py-3.5">
        <div className="w-[60%] animate-pulse rounded bg-slate-200 py-2.5" />
      </div>
    </Card>
  )
}
