import { JobsLoader, LocationsLoader } from '@/components/loaders'
import { Layout } from '@components/Layout'
import { Meta } from '@components/Seo'

export const Homepage = () => {
  const meta: Meta = {
    title: `Build your workforce faster with Teero`,
  }

  return (
    <Layout meta={meta}>
      <div className="mx-auto flex w-full max-w-[1400px] flex-col justify-center gap-6 p-5 md:flex-row">
        <div className="w-full md:max-w-[280px] ">
          <LocationsLoader />
        </div>
        <div className="w-full max-w-[1096px]">
          <JobsLoader />
        </div>
      </div>
    </Layout>
  )
}

export default Homepage
