import { Card } from '@people/ui/cards'

export const JobsLoader = () => {
  return (
    <div className="space-y-5">
      <Card>
        <div className="flex flex-col gap-2.5 border-b border-slate-200 px-[18px] py-3.5">
          <div className="w-[20%] animate-pulse rounded bg-slate-200 py-2.5" />
          <div className="w-[50%] animate-pulse rounded bg-slate-200 py-2.5" />
        </div>
      </Card>
      <Card>
        <div className="flex items-center justify-between border-b border-slate-200 px-[18px] h-14">
          <div className="w-[40%] animate-pulse rounded bg-slate-200 py-2.5" />
          {[...Array(5)].map((_, i) => (
            <div
              key={i}
              className="w-[10%] animate-pulse rounded bg-slate-200 py-2.5"
            />
          ))}
        </div>
        <div className="flex items-center justify-between border-b border-slate-200 px-[18px] h-12">
          <div className="w-[40%] animate-pulse rounded bg-slate-200 py-2.5" />
          {[...Array(5)].map((_, i) => (
            <div
              key={i}
              className="w-[10%] animate-pulse rounded bg-slate-200 py-2.5"
            />
          ))}
        </div>
        {[...Array(4)].map((_, i) => (
          <div
            key={i}
            className="flex h-16 items-center justify-between border-b border-slate-200 px-[18px] py-3.5"
          >
            <div className="w-[40%] animate-pulse rounded bg-slate-200 py-2.5" />
            {[...Array(5)].map((_, i) => (
              <div
                key={i}
                className="w-[10%] animate-pulse rounded bg-slate-200 py-2.5"
              />
            ))}
          </div>
        ))}
      </Card>
    </div>
  )
}
